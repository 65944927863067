import { motion } from "framer-motion";
import React from "react";
import data_ani from "../../assets/data_ani.gif";
import aa from "../../assets/nn.gif";

import { AppWrap } from "../../wrapper";

import { images } from "../../constants";
import "./Header.scss";

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

const Header = () => {
  const handleResumeClick = () => {
    window.open(
      "https://drive.google.com/file/d/10fVLUz4LA65k5ljf7hZIlwidL1anTmcE/view?usp=sharing",
      "_blank"
    );
  };

  return (
    <div className="app__header app__flex">
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <span>👋</span>
            <div style={{ marginLeft: 20 }}>
              <p className="p-text">Hello, I am</p>
              <h1 className="head-text">Dhrumit Patel</h1>
            </div>
          </div>

          <div className="tag-cmp app__flex">
            <p className="p-text">AI & ML Model Architect</p>
            <p className="p-text">Researcher in AI & Data Science</p>
            <p className="p-text">Empowering Data-Driven Solutions</p>
          </div>
        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1], delayChildren: 0.5 }}
        transition={{ duration: 0.5 }}
        className="app__header-img"
      >
        <img src={aa} alt="profile_bg" className="data_ani" />
        <motion.img
          whileInView={{ scale: [0, 1] }}
          transition={{ duration: 1, ease: "easeInOut" }}
          src={images.circle}
          alt="profile_circle"
          className="overlay_circle"
        />
      </motion.div>

      <motion.div
        variant={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles"
      >
        {[images.pandas, images.tensorflow, images.numpy, images.keras].map(
          (circle, index) => (
            <div className="circle-cmp app_flex" key={`circle-${index}`}>
              <img src={circle} alt="circle" />
            </div>
          )
        )}
      </motion.div>
      {/* Add the button for the resume */}
      <motion.div className="app__header-resume">
        <button onClick={handleResumeClick} className="resume-button">
          View/Download Resume
        </button>
      </motion.div>
    </div>
  );
};

export default AppWrap(Header, "home");
